/*

GLOBAL SCRIPTS	
	
These functions make sure WordPress
and Foundation play nice together.
*/
//(function($){})(jQuery);

var window = jQuery(window);
jQuery(document).ready(function($) {	
	//initDebugin();
	initJsGlobal();
	initOffCanvasSwitcher();		
	initNavigation();
	initGalleries();
	initReplaceFancybox();		
	clickToExpand();
	//initBrowserExposure();

});

var Swiper;
var Foundation;
// used to console log screen breakpoint changes while developing themes
function initDebugin() {
	var currentBreakpoint = Foundation.MediaQuery.current;
	//console.log('the current breakpoint is ' + currentBreakpoint);
	jQuery(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
		  // newSize is the name of the now-current breakpoint, oldSize is the previous breakpoint
	});
	
	jQuery(document).on('show.zf.dropdownMenu', function(ev, $el) {
   	 	console.log('dropdown has been opened');
	});
}

function initJsGlobal() {
	// Remove empty P tags created by WP inside of Accordion and Orbit
    jQuery('.accordion p:empty, .orbit p:empty').remove();

	// Adds Flex Video to YouTube and Vimeo Embeds
	jQuery('iframe[src*="youtube.com"], iframe[src*="vimeo.com"]').each(function() {
		if ( jQuery(this).innerWidth() / jQuery(this).innerHeight() > 1.5 ) {
		  jQuery(this).wrap("<div class='widescreen responsive-embed'/>");
		} else {
		  jQuery(this).wrap("<div class='responsive-embed'/>");
		}
	});
	
		
	// This fixes the flash bug on the navigation
	jQuery('.lower-bar').removeClass('wait-js');
	jQuery('.sticky-wrapper-navigation').removeClass('wait-js');	
	jQuery('.add-grid_wrapper .gform_body .gform_fields').addClass('grid-x');
	
	jQuery('<div class="svg-quote"><svg id="Component_2_1" data-name="Component 2 – 1" xmlns="http://www.w3.org/2000/svg" width="47" height="47" viewBox="0 0 47 47"><text id="_" data-name="“" transform="translate(0 108)" font-size="140" font-family="Helvetica"><tspan x="0" y="0">“</tspan></text></svg></div>').prependTo('blockquote');
	
}

var currentTransition = "",
currentPosition = "";

function initOffCanvasSwitcher() {

	var newSize = Foundation.MediaQuery.current;		
	setOffcanvasPosition(newSize);		

		
	jQuery(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
		// newSize is the name of the now-current breakpoint, oldSize is the previous breakpoint		 
		setOffcanvasPosition(newSize);		  
	});
	
	function setOffcanvasPosition(newSize) {		
		var mobileOffcanvas = jQuery('#off-canvas').data("mobile").split(" "),
		tabletOffcanvas = jQuery('#off-canvas').data("tablet").split(" "),
		offCanvas = jQuery('#off-canvas');
				
		if(newSize == 'xmedium' || newSize == 'medium'){		
			offCanvas.removeClass("position-"+mobileOffcanvas[0]).addClass("position-"+tabletOffcanvas[0]);
			offCanvas.removeClass("is-transition-"+mobileOffcanvas[1]).attr("data-transition", tabletOffcanvas[1]).addClass("is-transition-"+tabletOffcanvas[1]);			
			currentPosition = tabletOffcanvas[0];	
			currentTransition = tabletOffcanvas[1];					
		} 
		
		if(newSize == 'smedium' || newSize == 'small' || newSize == 'xsmall'){		
			offCanvas.removeClass("position-"+tabletOffcanvas[0]).addClass("position-"+mobileOffcanvas[0]);	
			offCanvas.removeClass("is-transition-"+tabletOffcanvas[1]).attr("data-transition", mobileOffcanvas[1]).addClass("is-transition-"+mobileOffcanvas[1]);			
			currentPosition	 = mobileOffcanvas[0];
			currentTransition = mobileOffcanvas[1];					
		}  
	}
		
	// ADDS class name to the body tag to disable scrolling when the offcanvas menu is open and adding transition and positioning class names
	jQuery('#off-canvas').on('opened.zf.offCanvas', function () {
        jQuery("body").addClass('offCanvasOpen');
		var navFixedState = Foundation.MediaQuery.is('xmedium down'),		
		hasIsAnchored = jQuery('.sticky-wrapper-navigation').hasClass('is-anchored');
		
        if(navFixedState && hasIsAnchored ){ jQuery('.sticky-wrapper-navigation').removeClass('is-anchored').addClass('is-stuck');}    
	
		jQuery(".hamburger").addClass("is-active");             
        jQuery('.off-canvas-content').removeClass('is-opened is-open-left has-position-left has-transition-push has-transition-overlap').addClass('is-opened is-open-'+currentPosition+' has-position-'+currentPosition+' has-transition-'+currentTransition);   		
    });
   
    jQuery('#off-canvas').on('close.zf.offCanvas', function () {
	    // Look for .hamburger
		jQuery(".hamburger").removeClass("is-active");
        jQuery("body").removeClass('offCanvasOpen');
        jQuery('.off-canvas-content').removeClass('is-opened is-open-'+currentPosition+' has-position-'+currentPosition);  
    });
}

function initGalleries(){
	jQuery('.imgGalItem').click(function(event) {
			
		var selector = jQuery(this),
			link = selector.attr('data-link'),
			rel  = selector.attr('rel'),
			height = selector.attr('height'),
			width = selector.attr('width'),
			counts = selector.attr('data-groups'),			
			newContent = '<a href="'+link+'" rel="galSlideCell'+rel+'" data-fancybox="group'+counts+'" data-caption="*Individual Results May Vary"><img src="'+link+'" height="+height+" width="+width+"></a>',
			containerHeight = jQuery(".galSlideCell"+rel+" .main-gal-img a").height();		
		
		jQuery(".photoGallery"+rel).removeClass("is-active");
		selector.addClass("is-active");		
			
		jQuery(".galSlideCell"+rel+" .main-gal-img").stop().css({'opacity':'0' , 'height':containerHeight+'px'}).html(function () {				
		 		return newContent;
		    }).animate({
		        opacity: 1
		    },500);	
		    
		setTimeout(function(){jQuery(".galSlideCell"+rel+" .main-gal-img").css('height','auto'); }, 500); 		
		event.preventDefault();	
		return false;
	});	

	jQuery('select#mobile-sorting').on('change', function() {
	  window.location.replace(this.value);
	});
	
}

function initReplaceFancybox(){
	var fancyboxLink = jQuery('.fancy-box a');
	var fancyboxLink2 =  jQuery('.fancybox a');
	if(fancyboxLink){
		
		fancyboxLink.attr('data-fancybox', '');
	}
	if(fancyboxLink2){
			
		fancyboxLink2.attr('data-fancybox', '');
	}

}


function initNavigation() {
	jQuery('.sticky-wrapper-navigation').on('sticky.zf.stuckto:top', function(){
	  	jQuery(this).addClass('shrink');
	}).on('sticky.zf.unstuckfrom:top', function(){
	  	jQuery(this).removeClass('shrink');
	})	
}

function clickToExpand(){	
	var expandTar = jQuery('.is-style-click-to-expand'),
		ehmax = '',
		expandTarBtn = '<a class="expandBtn"><span class="showMe">Click To Expand</span><span class="hideMe">Click To Collapse</span> <i class="rotateMe none fa fa-angle-double-right" aria-hidden="true"></i></a>';
	
	expandTar.wrapInner('<div class="expandrContent"></div>');
	expandTar.append(expandTarBtn);

	jQuery('.expandrContent').each( function(){
		ehmax = jQuery(this).parent().data('eh-max');
		jQuery(this).find('.expandrContent').css('max-height', ehmax);		
		jQuery(this).css('max-height', ehmax );
	});
	
	jQuery('.expandBtn').on('click', function(){
		jQuery(this).find('.rotateMe').toggleClass('none down');
		jQuery(this).find('span').toggleClass('showMe hideMe');
		jQuery(this).parent().find('.expandrContent').toggleClass('expanded');
		jQuery(this).toggleClass('expanded');	
	});
		
}


var navigator;
var DocumentTouch;


//	Animations v1.1, Joe Mottershaw (hellojd)
//	https://github.com/hellojd/animations / https://github.com/Sananes/animations

//	==================================================

//	Visible, Sam Sehnert, samatdf, TeamDF
//	https://github.com/teamdf/jquery-visible/
//	==================================================

	(function($){
		
		$.fn.visible = function(partial,hidden,direction) {
			var $t				= jQuery(this).eq(0),
				t				= $t.get(0),
				$w				= jQuery(window),
				viewTop			= $w.scrollTop(),
				viewBottom		= viewTop + $w.height(),
				viewLeft		= $w.scrollLeft(),
				viewRight		= viewLeft + $w.width(),
				_top			= $t.offset().top,
				_bottom			= _top + $t.height(),
				_left			= $t.offset().left,
				_right			= _left + $t.width(),
				compareTop		= partial === true ? _bottom : _top,
				compareBottom	= partial === true ? _top : _bottom,
				compareLeft		= partial === true ? _right : _left,
				compareRight	= partial === true ? _left : _right,
				clientSize		= hidden === true ? t.offsetWidth * t.offsetHeight : true;
				direction		= (direction) ? direction : 'both';

			if(direction === 'both')
				return !!clientSize && ((compareBottom <= viewBottom) && (compareTop >= viewTop)) && ((compareRight <= viewRight) && (compareLeft >= viewLeft));
			else if(direction === 'vertical')
				return !!clientSize && ((compareBottom <= viewBottom) && (compareTop >= viewTop));
			else if(direction === 'horizontal')
				return !!clientSize && ((compareRight <= viewRight) && (compareLeft >= viewLeft));
		};
		

		$.fn.fireAnimations = function(options) {
			function animate() {
				if (jQuery(window).width() >= 0) {
					jQuery('.animate__animated').each(function(i, elem) {
							elem = jQuery(elem);
							//var	type = jQuery(this).attr('data-anim-type'),
							//delay = jQuery(this).attr('data-anim-delay');

						if (elem.visible(true)) {
							setTimeout(function() {
								elem.addClass('trigger');
							}, 100);
						} 
					});
				} else {
					jQuery('.animate__animated').each(function(i, elem) {
							elem = jQuery(elem);
						var	type = jQuery(this).attr('data-anim-type'),
							delay = jQuery(this).attr('data-anim-delay');

							setTimeout(function() {
								elem.addClass(type);
							}, delay);
					});
				}
			}

			jQuery(document).ready(function() {
				jQuery('html').removeClass('no-js').addClass('js');

				animate();
			});

			jQuery(window).scroll(function() {
				animate();

				if (jQuery(window).scrollTop() + jQuery(window).height() == jQuery(document).height()) {
					animate();
				}
			});
		};

		jQuery('.animate').fireAnimations();

	})(jQuery);
		
	var	triggerClasses = 'flash strobe shake bounce tada wave spin pullback wobble pulse pulsate heartbeat panic explode';
		
	var	classesArray = [];
		classesArray = triggerClasses.split(' ');

	var	classAmount = classesArray.length;
	var type;
	
	function randomClass() {
		var	random = Math.ceil(Math.random() * classAmount);

		type = classesArray[random];

		return type;
	}

	function triggerOnce(target, type) {
		if (type == 'random') {
			type = randomClass();
		}

		jQuery(target).removeClass('trigger infinite ' + triggerClasses).addClass('trigger').addClass(type).one('webkitAnimationEnd oAnimationEnd MSAnimationEnd animationend', function() {
			jQuery(this).removeClass('trigger infinite ' + triggerClasses);
		});
	}

	function triggerInfinite(target, type) {
		if (type == 'random') {
			type = randomClass();
		}

		jQuery(target).removeClass('trigger infinite ' + triggerClasses).addClass('trigger infinite').addClass(type).one('webkitAnimationEnd oAnimationEnd MSAnimationEnd animationend', function() {
			jQuery(this).removeClass('trigger infinite ' + triggerClasses);
		});
	}

	jQuery(window).resize(function() {
		jQuery('.animate').fireAnimations();
	});


function initBrowserExposure() {
	


	var uaMatch = '', prefix = '';

	if (navigator.userAgent.match(/Windows/))
	{
	  jQuery('html').addClass('windows');
	}
	else if (navigator.userAgent.match(/Mac OS X/))
	{
	  jQuery('html').addClass('macOs');
	}
	else if (navigator.userAgent.match(/X11/))
	{
	  jQuery('html').addClass('x11');
	}

	var supports = (function() {
	var d = document.documentElement,
		c = "ontouchstart" in window || navigator.msMaxTouchPoints;
	if (c) {
		d.className += " touch";
		return {
			touch: true
		}
	} else {
		d.className += " no-touch";
		return {
			touch: false
		}
	}
	})();

	// browser
	if (navigator.userAgent.match(/Chrome/))
	{
	  uaMatch = ' Chrome/';
	  prefix = 'chrome';
	}
	else if (navigator.userAgent.match(/Safari/))
	{
	  uaMatch = ' Version/';
	  prefix = 'safari';
	}
	else if (navigator.userAgent.match(/Firefox/))
	{
	  uaMatch = ' Firefox/';
	  prefix = 'firefox';
	}
	else if (navigator.userAgent.match(/MSIE/))
	{
	  uaMatch = ' MSIE ';
	  prefix = 'x-msie';
	}
	// add result preifx as browser class
	if (prefix)
	{
	  jQuery('html').addClass(prefix);	   
	}

}

// Get IE or Edge browser version
var version = detectIE();

if (version === false) {
  jQuery('html').addClass('not-ie-edge');
} else if (version >= 12) {
   jQuery('html').addClass('edge edge-'+ version);
} else {
   jQuery('html').addClass('ie ie-'+ version);
}

// add details to debug result
//document.getElementById('details').innerHTML = window.navigator.userAgent;

/**
 * detect IE
 * returns version of IE or false, if browser is not Internet Explorer
 */
 
function detectIE() {
  var ua = window.navigator.userAgent;

  // Test values; Uncomment to check result …

  // IE 10
  // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';
  
  // IE 11
  // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';
  
  // Edge 12 (Spartan)
  // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';
  
  // Edge 13
  // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

  var msie = ua.indexOf('MSIE ');
  if (msie > 0) {
	// IE 10 or older => return version number
	return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  var trident = ua.indexOf('Trident/');
  if (trident > 0) {
	// IE 11 => return version number
	var rv = ua.indexOf('rv:');
	return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  var edge = ua.indexOf('Edge/');
  if (edge > 0) {
	// Edge (IE 12+) => return version number
	return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }

  // other browser
  return false;
}